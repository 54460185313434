import { FILTERS_EQUAL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "summary-report"
  }, [_c("a-row", [_c("a-col", {
    staticClass: "px-5 background--white",
    attrs: {
      "span": 24
    }
  }, [_c("h2", {
    staticClass: "h5 mb-3 neutral-10--text"
  }, [_vm._v("Export Orders Summary Report")])])], 1), _c("a-row", {
    staticClass: "p-5"
  }, [_c("a-col", {
    staticClass: "mb-3",
    attrs: {
      "span": 3
    }
  }, [_c("select-input", {
    attrs: {
      "value": _vm.selectedClient,
      "data-source": _vm.listClients,
      "source": "code",
      "source-label": "name"
    },
    on: {
      "change": _vm.onFilterChange
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 24
    }
  }, [_c("a-table", {
    attrs: {
      "bordered": "",
      "columns": _vm.columns,
      "data-source": _vm.dataSummaryReport,
      "row-key": "id",
      "pagination": false,
      "loading": _vm.isLoading
    }
  })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
var ListSummaryReport_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ListSummaryReport",
  inject: ["resourceProps"],
  data() {
    const currencyFormatter = new Intl.NumberFormat("en-US");
    const [clientProps, reportProps] = this.resourceProps;
    const selectedClient = "all";
    const columns = [
      {
        title: "Region",
        dataIndex: "region",
        key: "Region",
        sorter: false,
        customRender: (value, row, index2) => {
          if (index2 === this.dataSummaryReport.length - 1) {
            return {
              children: value,
              attrs: {
                colSpan: 4
              }
            };
          }
          return {
            children: value
          };
        }
      },
      {
        title: "Start of Month Snapshot (Local Currency)",
        dataIndex: "startOfMonthSnapshot",
        key: "StartOfMonthSnapshot",
        sorter: false,
        customRender: (value, row, index2) => {
          if (index2 === this.dataSummaryReport.length - 1) {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: this.formatCurrency(value, true, row.region)
          };
        }
      },
      {
        title: "Current Month (Local Currrency)",
        dataIndex: "currentMonth",
        key: "CurrentMonth",
        sorter: false,
        customRender: (value, row, index2) => {
          if (index2 === this.dataSummaryReport.length - 1) {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: this.formatCurrency(value, true, row.region)
          };
        }
      },
      {
        title: "Carry Over (Local Currency)",
        dataIndex: "carryOver",
        key: "CarryOver",
        sorter: false,
        customRender: (value, row, index2) => {
          if (index2 === this.dataSummaryReport.length - 1) {
            return {
              attrs: {
                colSpan: 0
              }
            };
          }
          return {
            children: this.formatCurrency(value, true, row.region)
          };
        }
      },
      {
        title: "Start of Month Snapshot (USD)",
        dataIndex: "startOfMonthSnapshotUSD",
        key: "StartOfMonthSnapshotUSD",
        sorter: false,
        customRender: (value) => {
          return {
            children: this.formatCurrency(value, false)
          };
        }
      },
      {
        title: "Current Month (USD)",
        dataIndex: "currentMonthUSD",
        key: "CurrentMonthUSD",
        sorter: false,
        customRender: (value) => {
          return {
            children: this.formatCurrency(value, false)
          };
        }
      },
      {
        title: "Carry Over (USD)",
        dataIndex: "carryOverUSD",
        key: "CarryOverUSD",
        sorter: false,
        customRender: (value) => {
          return {
            children: this.formatCurrency(value, false)
          };
        }
      }
    ];
    return {
      currencyFormatter,
      clientProps,
      reportProps,
      selectedClient,
      columns,
      isLoading: false
    };
  },
  computed: {
    listClients() {
      return [
        {
          id: 0,
          name: "All",
          code: "all"
        },
        ...this.clientProps.crud.getList()
      ];
    },
    dataSummaryReport() {
      return this.reportProps.crud.getList() || [];
    }
  },
  async created() {
    this.clientProps.crud.fetchList();
    this.reportProps.crud.deleteFilter("IsInactive");
    this.reportProps.crud.deletePagination();
    await this.fetchData();
  },
  methods: {
    formatCurrency(value, isLocalCurrency = false, region = "") {
      let currency = "USD";
      if (isLocalCurrency && region) {
        if (region.toLowerCase() === "australia") {
          currency = "AUD";
        }
        if (region.toLowerCase() === "new zealand") {
          currency = "NZD";
        }
      }
      const roundedVal = Math.round(value);
      return `${this.currencyFormatter.format(roundedVal)} ${currency}`;
    },
    async fetchData() {
      this.isLoading = true;
      await this.reportProps.crud.fetchList();
      this.isLoading = false;
    },
    async onFilterChange(val) {
      if (val.toLowerCase() === "all") {
        this.reportProps.crud.deleteFilter("Client");
      } else {
        this.reportProps.crud.setFilter("Client", {
          operator: FILTERS_EQUAL,
          value: val
        });
      }
      await this.fetchData();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "bb53f8f8", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListSummaryReport = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-summary-report"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": ["export-order.common.clients", "export-order.summary-reports"],
      "api-url": _vm.apiUrl
    }
  }, [_c("list-summary-report")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListSummaryReport
  },
  data() {
    return {
      apiUrl,
      itemsMenu: [
        {
          key: "summary-report",
          title: "Export Orders Summary Report",
          path: "/export-orders/summary-report"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
